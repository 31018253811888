(function (win, $) {
    'use strict';
    var fp = (win.fp = typeof win.fp !== 'undefined' ? win.fp : {}),
        home = (fp.home = typeof fp.home !== 'undefined' ? fp.home : {});

    var getScreenSize = function () {
        var pixelDensity = window.devicePixelRatio || 1;
        var width = window.innerWidth * pixelDensity;

        if (width >= 2400) {
            return 'xlarge';
        }
        if (width >= 1400) {
            return 'large';
        }
        if (width >= 1000) {
            return 'medium';
        }
        if (width >= 600) {
            return 'small';
        }
        return 'xsmall';
    };

    fp.home = (function () {
        var windowHeight;
        var initialized = false;

        // =========================================
        // Set Hero Size
        // =========================================
        var setHeroSize = function (initialLoad) {
            var height = $(window).height();
            var headerHeight = $('header').outerHeight(); // Fixed: added parentheses
            var specialMessageHeight = 0; // Default to 0 in case special message (banner) doesn't exist
            var fixedFooterHeight = 0; // Default to 0 in case fixed footer doesn't exist
            var logoMobileHeight = 0; // Default to 0 in case logo-mobile doesn't exist

            var specialMessage = $('#special-message.message-banner');
            if (specialMessage.length && specialMessage.is(':visible')) {
                specialMessageHeight = specialMessage.outerHeight();
            }

            var fixedFooter = $('#fixed-footer');
            if (fixedFooter.length && fixedFooter.is(':visible')) {
                fixedFooterHeight = fixedFooter.outerHeight();
            }

            var logoMobile = $('#logo-mobile');
            if (logoMobile.length && logoMobile.is(':visible')) {
                logoMobileHeight = logoMobile.outerHeight();
            }

            var maxHeight = height - specialMessageHeight - headerHeight - logoMobileHeight - fixedFooterHeight;
            $('.slideshow').css('height', maxHeight);
            //console.log("SLIDESHOW HEIGHT: " + maxHeight);

            if (initialLoad) {
                $('body').removeClass('fade-home-contents');
                setTimeout(function () {
                    $('body').addClass('hero-set');
                }, 1000);
            }
        };

        // =========================================
        // Set Background Image
        // =========================================
        var setBgImage = function (imgInfo) {
            var screenSize = getScreenSize();
            var slideshowEl = document.getElementsByClassName('slideshow-photo')[0];

            if (!slideshowEl || !Array.isArray(imgInfo)) {
                return; // Exit if slideshow element or imgInfo is invalid
            }

            // Filter images based on screen size
            var filteredImages = imgInfo.filter(function (img) {
                return img.assetSize === screenSize;
            });

            // Exit if no matching images found
            if (filteredImages.length === 0) {
                return;
            }

            // Pick a random image
            var randomNumber = Math.floor(Math.random() * filteredImages.length);
            var randomBackgroundImage = filteredImages[randomNumber];

            // Apply the background image
            slideshowEl.style.backgroundImage = "url('" + randomBackgroundImage.imagePath + "')";

            // Set accessibility attributes if altText is provided
            if (randomBackgroundImage.altText) {
                slideshowEl.setAttribute('aria-label', randomBackgroundImage.altText);
                slideshowEl.setAttribute('role', 'img');
            }
        };

        // =========================================
        // Set Video Controls
        // =========================================
        var setupVideoControls = function () {
            var video = document.getElementById('bg-video');
            var videoButton = document.getElementById('video-pause-play');
            var videoButtonIcon = videoButton.querySelector('span');
            var controlAnnouncements = document.getElementById('video-control-announcements');

            if (!video || !videoButton || !controlAnnouncements) {
                return;
            }

            videoButton.addEventListener('click', function () {
                if (!video.paused) {
                    video.pause();
                    videoButton.setAttribute('aria-label', 'Play video');
                    videoButtonIcon.classList.replace('icon-pause', 'icon-play');
                    controlAnnouncements.textContent = 'Video paused';
                } else {
                    video.play();
                    videoButton.setAttribute('aria-label', 'Pause video');
                    videoButtonIcon.classList.replace('icon-play', 'icon-pause');
                    controlAnnouncements.textContent = 'Video playing';
                }
            });
        };

        // Public API
        return {
            init: function () {
                $('.homepage-link').addClass('selected');

                // Initialize hero size
                setHeroSize(true);

                // Initialize video controls
                setupVideoControls();

                $(window).resize(function () {
                    setHeroSize(false);
                });
            },
            setBgImage: setBgImage
        };
    })();
})(window, $);